/**
 * @generated SignedSource<<b877e34b9ae8b5b20bd96929728c3c28>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NotificationsSummarySectionFragment_company$data = {
  readonly notifications: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly read: boolean;
        readonly severity: string;
        readonly " $fragmentSpreads": FragmentRefs<"NotificationFragment_notification">;
      };
    }>;
  } | null;
  readonly " $fragmentType": "NotificationsSummarySectionFragment_company";
};
export type NotificationsSummarySectionFragment_company$key = {
  readonly " $data"?: NotificationsSummarySectionFragment_company$data;
  readonly " $fragmentSpreads": FragmentRefs<"NotificationsSummarySectionFragment_company">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NotificationsSummarySectionFragment_company",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "NotificationConnection",
      "kind": "LinkedField",
      "name": "notifications",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "NotificationEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Notification",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "severity",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "read",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "NotificationFragment_notification"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Company",
  "abstractKey": null
};

(node as any).hash = "c53e036180122b53c037387385126472";

export default node;
