import React from 'react';
import {
  Heading,
  ResponsivePageGutters,
  Text,
  usePageGuttersPaddingValue,
} from '@attentive/picnic';
import { EventBannerProps } from '../types';
import { CloseButton, CtaButton } from './styledComponents';

export const StackedEventBanner: React.FC<EventBannerProps> = ({
  url,
  name,
  eventDate,
  description,
  callToActionText,
  onDismiss = () => {},
  css,
}) => {
  const pageGuttersPadding = usePageGuttersPaddingValue();

  return (
    <ResponsivePageGutters
      css={{
        width: '100%',
        position: 'relative',
        backgroundColor: '$bgDecorative2Default',
        py: '$space3',
        ...css,
      }}
    >
      <Heading variant="subheading" css={{ mb: '$space1' }}>
        {eventDate}
      </Heading>
      <Heading variant="md" css={{ mb: '$space1' }}>
        {name}
      </Heading>
      <Text variant="caption" css={{ mb: '$space2' }}>
        {description}
      </Text>
      <CtaButton url={url} size="small">
        {callToActionText}
      </CtaButton>
      <CloseButton
        onClick={onDismiss}
        css={{ position: 'absolute', top: '$space0', right: pageGuttersPadding }}
      />
    </ResponsivePageGutters>
  );
};
