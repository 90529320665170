import React from 'react';
import { Button } from '@attentive/picnic';
import { Link } from 'react-router-dom';
import { ButtonAction } from '@attentive/data/types';

interface ModalButtonProps {
  buttonAction: string;
  buttonText: string;
  buttonUrl?: string;
  handleClose: () => void;
  variant: 'primary' | 'secondary';
}

export const ModalButton: React.VFC<ModalButtonProps> = ({
  buttonAction,
  buttonText,
  buttonUrl,
  handleClose,
  variant,
}) => {
  if (buttonAction === ButtonAction.ButtonActionClose) {
    return (
      <Button onClick={handleClose} variant={variant}>
        {buttonText}
      </Button>
    );
  }
  const isExternalUrl = buttonUrl?.startsWith('http');
  if (isExternalUrl) {
    return (
      <Button
        as="a"
        href={buttonUrl}
        target="_self"
        variant={variant}
        data-client-ui-id="modal-button"
        onClick={handleClose}
      >
        {buttonText}
      </Button>
    );
  } else if (buttonUrl) {
    return (
      <Button
        as={Link}
        to={buttonUrl}
        target="_self"
        variant={variant}
        onClick={handleClose}
        data-client-ui-id="modal-button"
      >
        {buttonText}
      </Button>
    );
  }
  return null;
};
