import { useFragment, useLazyLoadQuery } from 'react-relay';
import FindActiveDismissibleModalQuery, {
  FindActiveDismissibleModalQuery as ActiveDismissibleModalQueryType,
} from './__generated__/FindActiveDismissibleModalQuery.graphql';
import FindActiveDismissibleModalFragment_query, {
  FindActiveDismissibleModalFragment_query$key,
} from './__generated__/FindActiveDismissibleModalFragment_query.graphql';
import { useCurrentUser } from '@attentive/acore-utils';

export const useActiveDismissibleModal = () => {
  const windowUrl = window.location.href;
  const {
    id: userId,
    company: { id: companyId },
  } = useCurrentUser();

  const data = useLazyLoadQuery<ActiveDismissibleModalQueryType>(FindActiveDismissibleModalQuery, {
    url: windowUrl,
    userId,
    companyId,
  });
  const { activeDismissibleModal } = useFragment<FindActiveDismissibleModalFragment_query$key>(
    FindActiveDismissibleModalFragment_query,
    data
  );
  return activeDismissibleModal;
};
