/**
 * @generated SignedSource<<8451213b04d797a9ccb0a27986e1d1c5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FindActiveDismissibleModalQuery$variables = {
  companyId: string;
  url: string;
  userId: string;
};
export type FindActiveDismissibleModalQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"FindActiveDismissibleModalFragment_query">;
};
export type FindActiveDismissibleModalQuery = {
  response: FindActiveDismissibleModalQuery$data;
  variables: FindActiveDismissibleModalQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "companyId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "url"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userId"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "FindActiveDismissibleModalQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "FindActiveDismissibleModalFragment_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "FindActiveDismissibleModalQuery",
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "companyId",
            "variableName": "companyId"
          },
          {
            "kind": "Variable",
            "name": "url",
            "variableName": "url"
          },
          {
            "kind": "Variable",
            "name": "userId",
            "variableName": "userId"
          }
        ],
        "concreteType": "DismissibleModal",
        "kind": "LinkedField",
        "name": "activeDismissibleModal",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "imageUrl",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "endTime",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "startTime",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "url",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "primaryButtonAction",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "primaryButtonText",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "primaryButtonUrl",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "secondaryButtonAction",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "secondaryButtonText",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "secondaryButtonUrl",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "modalId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "featureFlags",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b60f3aa3dedb9163d619a67b8f133b5e",
    "id": null,
    "metadata": {},
    "name": "FindActiveDismissibleModalQuery",
    "operationKind": "query",
    "text": "query FindActiveDismissibleModalQuery(\n  $userId: ID!\n  $companyId: ID!\n  $url: String!\n) {\n  ...FindActiveDismissibleModalFragment_query\n}\n\nfragment FindActiveDismissibleModalFragment_query on Query {\n  activeDismissibleModal(url: $url, userId: $userId, companyId: $companyId) {\n    id\n    imageUrl\n    endTime\n    startTime\n    url\n    description\n    title\n    primaryButtonAction\n    primaryButtonText\n    primaryButtonUrl\n    secondaryButtonAction\n    secondaryButtonText\n    secondaryButtonUrl\n    modalId\n    featureFlags\n  }\n}\n"
  }
};
})();

(node as any).hash = "07d60258cb2a77005f7c52ca4e02f631";

export default node;
