import React from 'react';
import { Button, ButtonProps, Icon, Link, PicnicCss } from '@attentive/picnic';

const PU_LINK_BUTTON_TEXT_DECORATION = { textDecoration: 'none !important' };

interface CtaButtonProps extends Pick<ButtonProps, 'size' | 'css'> {
  url: string;
}

export const CtaButton: React.FC<CtaButtonProps> = ({ url, css, ...rest }) => {
  return (
    <Button
      as={Link}
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      variant="basic"
      css={{ ...PU_LINK_BUTTON_TEXT_DECORATION, ...css }}
      data-nav-id="marketing-banner-cta"
      {...rest}
    />
  );
};

export const CloseButton: React.VFC<{
  css: PicnicCss;
  onClick: () => void;
}> = (props) => {
  return (
    <Button variant="subdued" name="Close" data-nav-id="marketing-banner-dismiss" {...props}>
      <Icon name="X" mode="presentational" description="Close" size="small" />
    </Button>
  );
};
