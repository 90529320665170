import React from 'react';
import { Box, Heading, ResponsivePageGutters, Text, Separator } from '@attentive/picnic';
import { EventBannerProps } from '../types';
import { CloseButton, CtaButton } from './styledComponents';

export const InlineEventBanner: React.FC<EventBannerProps> = ({
  url,
  name,
  eventDate,
  description,
  callToActionText,
  onDismiss = () => {},
  css,
}) => {
  return (
    <ResponsivePageGutters
      css={{
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '$bgDecorative2Default',
        py: '$space4',
        ...css,
      }}
    >
      <Box>
        <Heading as="span" variant="subheading" css={{ display: 'block' }}>
          {eventDate}
        </Heading>
        <Heading as="span" variant="md">
          {name}
        </Heading>
      </Box>
      <Box css={{ justifySelf: 'stretch' }}></Box>
      <Box css={{ alignSelf: 'center', flex: 1, display: 'flex' }}>
        <Separator
          orientation="vertical"
          css={{ mx: '$space4', backgroundColor: '$grayscale1000', height: 'unset' }}
        />
        <Text variant="caption">{description}</Text>
      </Box>
      <CtaButton url={url} css={{ ml: '$space4' }}>
        {callToActionText}
      </CtaButton>
      <CloseButton
        onClick={onDismiss}
        css={{ display: 'flex', alignSelf: 'center', padding: '0', ml: '$space4' }}
      />
    </ResponsivePageGutters>
  );
};
