import React from 'react';

import { InlineEventBanner } from './components/InlineEventBanner';
import { StackedEventBanner } from './components/StackedEventBanner';
import { EventBannerProps } from './types';

export interface MarketingEventBannerProps extends EventBannerProps {
  mode?: 'inline' | 'stacked';
}

export const MarketingEventBanner: React.VFC<MarketingEventBannerProps> = ({
  mode = 'inline',
  ...rest
}) => {
  switch (mode) {
    case 'stacked':
      return <StackedEventBanner {...rest} />;
    case 'inline':
      return <InlineEventBanner {...rest} />;
  }
};
